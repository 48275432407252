import { FC, useEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import { mutate } from 'swr';
import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getLocalStorage } from 'helpers/utils/localStorage';
import { desktop } from 'helpers/utils/screensizes';
import { getLocalizationInfo } from 'project.config';
import { useCart } from 'frontastic';
import countries from 'shared/types/countries.json';
import SummaryAccordion from './components/summary-accordion';
import { OrderSummaryProps } from './types';
import OrderItemsListing from '../order-items-listing';
import OrderPaymentSection from '../order-payment-section';
import DiscountForm from '../order-payment-section/components/discount-form';

const OrderSummary: FC<OrderSummaryProps> = ({
  title,
  className,
  includeLoginSuggestion,
  includeSummaryAccordion,
  includeItemsList,
  searchParams,
  projectConfig,
  includeFeeAndTax,
  onVerifiedSummaryLocale,
  ...props
}) => {
  const [isDesktopSize] = useMediaQuery(desktop);

  const { data } = useCart();
  const { locale } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isVerifiedLocale, setIsVerifiedLocale] = useState(false);

  const itemsListClassName = useClassNames(['mb-24 border-y border-neutral-400', props.classNames?.itemsList]);

  useEffect(() => {
    const load = async () => {
      if (!data || !data?.billingAddress) {
        setIsLoading(true);
        return;
      }
      if (!isLoading && isVerifiedLocale) return;
      const currencyCode = getLocalizationInfo(locale).currency;
      const currentCountry = getLocalStorage('padiUserCountry');
      if (data?.billingAddress?.country) {
        if (data?.sum?.currencyCode && currencyCode === data?.sum?.currencyCode) {
          if (onVerifiedSummaryLocale) {
            onVerifiedSummaryLocale();
            const additionalAddressInfo = JSON.parse(data?.billingAddress?.additionalAddressInfo || '');
            const country = countries.find((c) => c.id === additionalAddressInfo.taxAddress.countryId);
            const isSameCountry = country?.twoLetterISORegionName.toLowerCase() === currentCountry;
            setIsLoading(!isSameCountry);
            setIsVerifiedLocale(isSameCountry);
          } else {
            setIsVerifiedLocale(true);
          }
        }
      } else if (onVerifiedSummaryLocale) {
        setIsLoading(false);
        onVerifiedSummaryLocale();
        setIsVerifiedLocale(false);
      }
    };
    if (includeFeeAndTax) {
      load();
    } else {
      const market = getLocalizationInfo(locale);
      if (data?.sum?.currencyCode !== market.currency.toLocaleUpperCase()) {
        mutate('/action/cart/getCart');
      }
      setIsLoading(false);
      setIsVerifiedLocale(true);
    }
  }, [data]);

  return (
    <div className={className}>
      {!isLoading && isVerifiedLocale && (
        <>
          {(title || includeLoginSuggestion) && (
            <div className="py-16 md:py-24 lg:pb-24 lg:pt-0">
              {title && <Typography className="md:text-18">{title}</Typography>}
            </div>
          )}

          {includeItemsList && props.order?.lineItems && (
            <OrderItemsListing className={itemsListClassName} lineItems={props.order?.lineItems} />
          )}

          {includeSummaryAccordion && (
            <SummaryAccordion order={props.order} cart={data} includeFeeAndTax={includeFeeAndTax} />
          )}

          {!isDesktopSize && includeSummaryAccordion && (
            <DiscountForm className={props.classNames?.applyDiscountButton} />
          )}

          {(isDesktopSize || !includeSummaryAccordion) && (
            <OrderPaymentSection
              classNames={{
                applyDiscountButton: 'py-14 text-16',
                totalAmount: 'text-18 md:pb-20',
                subCostsContainer: 'pt-12 md:pt-16 mb-20 lg:py-24 lg:mb-16 lg:border-b border-neutral-400',
              }}
              includeFeeAndTax={includeFeeAndTax}
              {...props}
            />
          )}
        </>
      )}
    </div>
  );
};

export default OrderSummary;
